import { compile } from "path-to-regexp";

import { debug } from "./helpers";

export enum RoutePathName {
    usersDetails = "usersDetails",
    home = "home",
    login = "login",
    notFound = "notFound",
    privacy = "privacy",
    forgottenPassword = "forgottenPassword",
    resetPassword = "resetPassword",
    customerList = "customerList",
    test = "test",

    // Super Admin
    applicationList = "applicationList",
    organizationList = "organizationList",
    valueListList = "valueListList",
    roleList = "roleList",
    jobList = "jobList",

    chooseHub = "chooseHub",
    storesList = "storesList",
    carriersList = "carriersList",
    trailersList = "trailersList",
    driversList = "driversList",
    settings = "settings",
    usersList = "usersList",
    valueListItems = "valueListItems",
    warehousesAndPlatforms = "warehousesAndPlatforms",
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.usersList]: "/users",
    [RoutePathName.usersDetails]: "/users/:userId",
    [RoutePathName.home]: "/",
    [RoutePathName.login]: "/login",
    [RoutePathName.notFound]: "/404",
    [RoutePathName.privacy]: "/confidentialite",
    [RoutePathName.forgottenPassword]: "/mot-de-passe-oublie",
    [RoutePathName.resetPassword]: "/reset-password",
    [RoutePathName.customerList]: "/clients",
    // enum based param example, any param that does not match the enum will result in a 404
    [RoutePathName.test]: `/test/:param(${Object.values(RoutePathName).join("|")})`,

    // Super Admin
    [RoutePathName.organizationList]: "/super-admin/organizations",
    [RoutePathName.applicationList]: "/super-admin/applications",
    [RoutePathName.valueListList]: "/super-admin/values-lists",
    [RoutePathName.roleList]: "/super-admin/roles",
    [RoutePathName.jobList]: "/super-admin/jobs",

    [RoutePathName.chooseHub]: "/centrale",
    [RoutePathName.storesList]: "/magasins",
    [RoutePathName.carriersList]: "/transporteurs",
    [RoutePathName.trailersList]: "/remorques",
    [RoutePathName.driversList]: "/chauffeurs",
    [RoutePathName.settings]: "/parametres",
    [RoutePathName.warehousesAndPlatforms]: "/parametres/warehousesAndPlatforms",
    [RoutePathName.valueListItems]: "/parametres/:valueListSlug",
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!routes[path]) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return "/404";
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string
) => {
    let route = getRawRoute(path);
    const compiler = compile(route, { encode: encodeURIComponent });

    try {
        route = compiler(params);
    } catch (error) {
        debug.error("[getRoute] route compile error :", error);
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
