// Types of the backend API
type ObjectId = string;

export enum DeviceType {
    ios = "ios",
    android = "android",
    web = "web",
    koala = "koala",
    api = "api",
    nothing = "",
}

export interface Organization {
    id: string;
    name: string;
    slug: string;
    reference: string;
    applications?: Application[];
    hasBeenSeen: boolean;
    parent?: Organization;
    children?: Organization[];
    createdAt: string;
    upStringdAt: string;
    logoUrl?: string;
}

export enum PermissionRight {
    read = "r",
    write = "w",
    disabled = "disabled",
}

export enum Permission {
    superAdmin = "superAdmin",
    admin = "admin",
    logAs = "logAs",
    users = "users",
    roles = "roles",
    backoffice = "backoffice",
    organizations = "organizations",
    applications = "applications",
    staticPages = "staticPages",
    questions = "questions",
    valueLists = "valueLists",

    stores = "stores",
    carriers = "carriers",
    trailers = "trailers",
    drivers = "drivers",
    settings = "settings",
}

export enum RoleScope {
    organizations = "organizations",
    users = "users",
    groups = "groups",
}

export interface Scope {
    organizations: ObjectId[];
    users: ObjectId[];
}
export interface Role {
    id: ObjectId;
    name: string;
    slug: RoleSlug;
    isRemovable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScope]?: boolean;
    };
}
export enum RoleSlug {
    superAdmin = "superAdmin",
    owner = "owner",
    admin = "admin",
    user = "user",
    driver = "driver",
}

export interface UserScope {
    // [key in RoleScope]?: ObjectId[];
    [RoleScope.organizations]?: Organization[];
    [RoleScope.users]?: User[];
}
export interface User {
    id: ObjectId;
    createdAt?: string;
    updatedAt?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    username?: string;
    token?: string;
    phone?: string;
    hasAcceptedTerms?: boolean;
    hasAcceptedTermsAt?: string;
    isDeleted?: boolean;
    deletedAt?: string;
    avatarUrl?: string;
    language?: SupportedLanguage;
    role?: Role;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        // [key in RoleScope]?: ObjectId[];
        [RoleScope.organizations]?: ObjectId[] | Organization[];
        [RoleScope.users]?: ObjectId[] | User[];
    };
    organization?: Organization;
    carrier?: Carrier;
}

export interface Coordinates {
    id?: ObjectId;
    latitude: number;
    longitude: number;
}
export enum AddressType {
    personal = "personal",
    professional = "professional",
    other = "other",
}
export interface Address {
    id?: ObjectId;
    type?: AddressType;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    oneLineAddress?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    hasEelevator?: boolean;
    hasIntercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}

export type Translation = {
    [key in SupportedLanguage]?: string;
};

export enum SupportedLanguage {
    fr = "fr",
    // en = "en",
    // it = "it",
    // es = 'es',
    // du = 'du',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // pt = 'pt',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export const LanguagesList: SupportedLanguage[] = [SupportedLanguage.fr];

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ApiInfos {
    name: string;
    version: string;
    env: "development" | "production" | "preprod";
    instance: string;
    request: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface QuestionSection {
    id: ObjectId;
    title: Translation | string;
    description?: Translation | string;
    questionCount?: number;
    questions?: Question[];
}

export interface Question {
    id: ObjectId;
    section?: QuestionSection;
    title: Translation | string;
    description?: Translation | string;
    answer: Translation | string;
}

export interface Application {
    id: string;
    name: string;
    organization?: Organization;
    applicationClients?: ApplicationClient[];
}

export interface ApplicationClient {
    id: string;
    name: string;
    apiKey: string;
    isPrivate: boolean;
    reference: string;
    type: DeviceType;
    options: {
        isBackoffice?: boolean;
    };
    iosOptions?: {
        authentificationPush: string;
        keyId: string;
        teamId: string;
        bundleId: string;
        stagingServer: boolean;
    };
    androidOptions?: {
        certificatPush: string;
        bundleId: string;
    };
    application?: Application;
    organization?: Organization;
}

export enum NotificationStatus {
    pending = "pending", // not useful here
    sent = "sent", // not useful here
    received = "received",
    opened = "opened",
    failed = "failed", // not useful here
}

export enum NotificationContextType {}

export interface Notification {
    id: ObjectId;
    type: "text";
    channel: "pull";
    payload: {
        text: string;
        context?: {
            type: NotificationContextType;
            [key: string]: any;
        };
    };
    reference: string;
    status: NotificationStatus;
    sendAt: string;
}

export enum ValueListFieldValueType {
    weekday = "weekday",
    string = "string",
    number = "number",
    boolean = "boolean",
    enum = "enum",
}

export enum ValueListFieldUnit {
    second = "second",
    minute = "minute",
    hour = "hour",
    day = "day",
    week = "week",
    month = "month",
    year = "year",
}

export interface ValueListField {
    id: string;
    title: string;
    columnTitle?: string;
    isSortable?: boolean;
    sortKey?: string;
    name: string;
    valueType: ValueListFieldValueType;
    unit?: ValueListFieldUnit;
    minValue?: number;
    maxValue?: number;
    isEnabled: boolean;
    isRequired?: boolean;
    enum?: string[];
}
export interface ValueList {
    id: string;
    title: string;
    slug: ValueListSlug;
    fields: ValueListField[];
    meta: {
        [key: string]: any;
    };
}
export enum ValueListSlug {
    delays = "delays",
    reasonsRefusingTruck = "reasonsRefusingTruck",
    packagingTypes = "packagingTypes",
    trailerDropZones = "trailerDropZones",
    trailerTypes = "trailerTypes",
    driverHazards = "driverHazards",
    reasonsForNonCompliance = "reasonsForNonCompliance",
    warehouses = "warehouses",
    platforms = "platforms",
}

export interface ValueListItem {
    id: string;
    title: string;
    fields: {
        [key: string]: string | number;
    };
    meta: {
        [key: string]: any;
    };
}

export enum AgendaJobStatus {
    finished = "finished",
    failed = "failed",
    scheduled = "scheduled",
    running = "running",
}
export interface AgendaJob {
    id: ObjectId;
    name: string;
    type: string;
    priority: number;
    failCount?: number;
    failReason?: string;
    failedAt?: string;
    nextRunAt?: string;
    lastModifiedBy?: string | null;
    lockedAt?: string | null;
    lastRunAt?: string | null;
    lastFinishedAt?: string;
    status?: AgendaJobStatus;
    data: {
        [key: string]: unknown;
    };
}
export interface RemoteFile {
    id: string;
    originalName: string;
    mimeType: string;
    url: string;
    thumbnailUrl?: string;
    name?: string;
}

export interface Store {
    id: ObjectId;
    organization: Organization;
    reference: string;
    name: string;
    address: Address;
    numberOfDocks: number;
    qrCode: string;
    type: StoreType;
    secondaryAddress?: Address;
    secondaryNumberOfDocks?: number;
    secondaryQrCode?: string;
    contacts?: User[];
    createdAt: string;
    upStringdAt: string;
}

export enum CarrierAttachment {
    carrier = "carrier",
    scapnor = "scapnor",
}

export enum StoreType {
    drive = "drive",
    express = "express",
    hyper = "hyper",
    super = "super",
}

export interface Carrier {
    id: ObjectId;
    organization: Organization;
    reference: string;
    name: string;
    phone: string;
    address: Address;
    attachment: CarrierAttachment;
    createdAt: string;
    upStringdAt: string;
}

export enum TrailerContract {
    permanent = "permanent",
    temporary = "temporary",
}

export enum TrailerStatus {
    allocated = "allocated",
    loading = "loading",
    loaded = "loaded",
    onDelivery = "onDelivery",
    inReturnWithPackaging = "inReturnWithPackaging",
    overhang = "overhang",
    inactive = "inactive",
    inReturnWithoutPackaging = "inReturnWithoutPackaging",
    available = "available",
}

export interface Trailer {
    id: ObjectId;
    organization: Organization;
    reference: string;
    registration: string;
    contract: TrailerContract;
    type: ValueListItem;
    location?: ValueListItem;
    status: TrailerStatus;
    scheduledReturn?: string;
    createdAt: string;
    updatedAt: string;
}
