/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <g fill="currentColor" clipPath="url(#exclamation-circle_svg__a)">
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0Zm0 21.964c-5.502 0-9.964-4.462-9.964-9.964 0-5.502 4.462-9.964 9.964-9.964 5.502 0 9.964 4.462 9.964 9.964 0 5.502-4.462 9.964-9.964 9.964Z" />
            <path d="M10.714 16.714a1.286 1.286 0 1 0 2.571 0 1.286 1.286 0 0 0-2.571 0Zm.643-3h1.285a.215.215 0 0 0 .215-.214V6.214A.215.215 0 0 0 12.642 6h-1.285a.215.215 0 0 0-.215.214V13.5c0 .118.097.214.215.214Z" />
        </g>
        <defs>
            <clipPath id="exclamation-circle_svg__a">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgExclamationCircle: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-exclamation-circle", props.className)} />
));

export default SvgExclamationCircle;
