import { VFC } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Avatar, Menu, Button } from "antd";
import { useModal } from "@ebay/nice-modal-react";
import { EditOutlined, LockOutlined } from "@ant-design/icons";

import { getRoute, RoutePathName } from "../routes";
import { useAuth } from "../context/AuthContext";
import { Logout } from "./icons";
import ProfileFormDrawer from "./drawers/ProfileFormDrawer";
import PasswordFormDrawer from "./drawers/PasswordFormDrawer";

const UserDropdown: VFC = () => {
    const { user, logout } = useAuth();
    const profileFormDrawer = useModal(ProfileFormDrawer);
    const passwordFormDrawer = useModal(PasswordFormDrawer);
    const menu = (
        <Menu>
            <Menu.Item
                icon={<EditOutlined />}
                key="profileDrawer"
                onClick={() => {
                    profileFormDrawer.show();
                }}
            >
                Votre compte
            </Menu.Item>
            <Menu.Item
                icon={<LockOutlined />}
                key="passwordDrawer"
                onClick={() => {
                    passwordFormDrawer.show();
                }}
            >
                Modifier le mot de passe
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" icon={<Logout />}>
                <Link
                    to={getRoute(RoutePathName.login)}
                    onClick={() => {
                        logout.mutate();
                    }}
                >
                    Déconnexion
                </Link>
            </Menu.Item>
        </Menu>
    );

    return user ? (
        <Dropdown trigger={["click"]} overlay={menu} placement="topRight">
            <Button id="user-menu-button" type="text">
                <Avatar size={40}>{user?.firstName?.substring(0, 1)}</Avatar>
                <div id="user-menu-button-content">
                    <span>{user?.firstName}</span>
                </div>
            </Button>
        </Dropdown>
    ) : null;
};

export default UserDropdown;
