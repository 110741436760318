/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28">
        <g clipPath="url(#mid-truck_svg__a)">
            <path
                fill="currentColor"
                d="M27.538 10.873h-2.502V3.448c0-1.134-.888-2.048-1.98-2.048H1.98C.888 1.4 0 2.319 0 3.448v17.975c0 1.134.888 2.048 1.98 2.048h2.839c.578 1.579 1.95 2.78 3.593 3.057 2.335.397 4.411-.995 5.17-3.057h9.47c1.096 0 1.98-.919 1.98-2.048v-7.378h2.496a.472.472 0 0 0 .463-.478v-2.215a.472.472 0 0 0-.463-.479h.01ZM10.576 22.327l-.019.043c-.032.072-.07.144-.11.206l-.028.043a1.467 1.467 0 0 1-.417.416c-.064.044-.13.082-.203.115-.014.005-.028.015-.042.02a1.472 1.472 0 0 1-.61.11c-.037 0-.079-.01-.116-.015a1.81 1.81 0 0 1-.222-.043 1.597 1.597 0 0 1-.314-.134c-.028-.014-.06-.033-.088-.048a1.534 1.534 0 0 1-.25-.205 1.801 1.801 0 0 1-.199-.259c-.018-.029-.032-.062-.046-.09a1.208 1.208 0 0 1-.092-.211c-.014-.038-.023-.077-.037-.115a1.997 1.997 0 0 1-.042-.23c0-.038-.01-.076-.01-.12 0-.1 0-.2.02-.305.018-.115.046-.225.087-.326.005-.014.014-.029.019-.043.032-.072.069-.143.11-.206.01-.014.02-.028.028-.043a1.47 1.47 0 0 1 .375-.387l.042-.029c.064-.043.129-.081.198-.115.014-.005.028-.014.042-.019a1.472 1.472 0 0 1 .61-.11c.037 0 .074.01.116.01.074.01.148.024.222.043.037.01.074.024.111.033.07.029.139.062.203.096.028.014.06.029.088.048.18.12.333.277.449.464.018.029.032.062.046.09.037.068.07.14.093.211.014.038.023.077.032.115.018.077.032.153.042.23 0 .038.009.076.009.12 0 .1 0 .2-.019.305a1.46 1.46 0 0 1-.088.326l.01.01Zm11.394-2.114h-8.31c-.536-1.675-1.951-2.962-3.662-3.254-2.41-.407-4.546 1.082-5.244 3.254H3.06V4.658h18.904v15.555h.005Z"
            />
        </g>
        <defs>
            <clipPath id="mid-truck_svg__a">
                <path fill="currentColor" d="M0 1.4h28v25.2H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgMidTruck: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-mid-truck", props.className)} />
));

export default SvgMidTruck;
