import { FC, ReactNode } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { SiderProps } from "antd/lib/layout";
import type { Location } from "history";
import { Layout, Menu, Typography } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, StarOutlined } from "@ant-design/icons";
import "../assets/styles/MainMenu.less";

import constants from "../config/constants";
import { getRoute, RoutePathName } from "../routes";
import { useLayout } from "../context/LayoutContext";
import { hasPermission } from "../helpers/security";
import { Permission, PermissionRight } from "../queries/api/types";
import { useAuth } from "../context/AuthContext";
import { Gear, MidTruck, Store, Truck } from "./icons";

const getSelectedKey = (location: Location) => {
    if (location.pathname.startsWith(getRoute(RoutePathName.settings))) {
        return getRoute(RoutePathName.settings);
    }
    return location.pathname;
};

interface MenuItem {
    key: string;
    to?: LinkProps["to"];
    href?: string; // used for outgoing links
    target?: string; // used for outgoing links
    onClick?: () => void;
    label: ReactNode;
    icon?: any;
    children?: MenuItem[];
    childKeys?: string[];
    disabled?: boolean;
    permissions?: Array<{ permission: Permission; permissionRight?: PermissionRight }>;
}

const menuItems: MenuItem[] = [
    {
        key: getRoute(RoutePathName.storesList),
        to: getRoute(RoutePathName.storesList),
        label: "Magasins",
        icon: <Store />,
        permissions: [{ permission: Permission.stores }],
    },
    {
        key: getRoute(RoutePathName.carriersList),
        to: getRoute(RoutePathName.carriersList),
        label: "Transporteurs",
        icon: <Truck />,
        permissions: [{ permission: Permission.carriers }],
    },
    {
        key: getRoute(RoutePathName.trailersList),
        to: getRoute(RoutePathName.trailersList),
        label: "Remorques",
        icon: <MidTruck />,
        permissions: [{ permission: Permission.trailers }],
    },
    // {
    //     key: getRoute(RoutePathName.driversList),
    //     to: getRoute(RoutePathName.driversList),
    //     label: "Chauffeurs",
    //     icon: <Peoples />,
    //     permissions: [{ permission: Permission.drivers }],
    // },
    {
        key: getRoute(RoutePathName.settings),
        to: getRoute(RoutePathName.settings),
        label: "Paramètres",
        icon: <Gear />,
        permissions: [{ permission: Permission.settings }],
    },
    {
        key: "superAdmin",
        label: "Super Admin",
        icon: <StarOutlined />,
        permissions: [{ permission: Permission.superAdmin }],
        children: [
            {
                key: getRoute(RoutePathName.applicationList),
                to: getRoute(RoutePathName.applicationList),
                label: "Applications",
                permissions: [{ permission: Permission.superAdmin }],
            },
            {
                key: getRoute(RoutePathName.organizationList),
                to: getRoute(RoutePathName.organizationList),
                label: "Organisations",
                permissions: [{ permission: Permission.superAdmin }],
            },
            {
                key: getRoute(RoutePathName.valueListList),
                to: getRoute(RoutePathName.valueListList),
                label: "Listes de valeurs",
                permissions: [{ permission: Permission.superAdmin }],
            },
            {
                key: getRoute(RoutePathName.roleList),
                to: getRoute(RoutePathName.roleList),
                label: "Rôles",
                permissions: [{ permission: Permission.superAdmin }],
            },
        ],
    },
];

const getDefaultOpenKeys = (pathname: string) => {
    const topLevelItem = menuItems.find((menuItem) =>
        menuItem.children?.some(
            (child) =>
                child.key === pathname ||
                child.childKeys?.includes(pathname) ||
                pathname.startsWith(child.key) ||
                child.childKeys?.some((childKey) => pathname.startsWith(childKey.substring(0, -1)))
        )
    );

    return topLevelItem ? [topLevelItem.key] : undefined;
};

const MainMenu: FC = () => {
    const { user } = useAuth();
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useLayout();
    const location = useLocation();
    const onCollapse: SiderProps["onCollapse"] = (value) => {
        setIsSidebarCollapsed(value);
    };

    return (
        <Layout.Sider
            collapsible
            collapsed={isSidebarCollapsed}
            onCollapse={onCollapse}
            trigger={isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            collapsedWidth={60}
            width={254}
            defaultCollapsed={true}
        >
            <div id="sidebar-wrapper">
                <Menu
                    mode="inline"
                    selectedKeys={[getSelectedKey(location)]}
                    defaultOpenKeys={getDefaultOpenKeys(location.pathname)}
                    theme="dark"
                >
                    {menuItems
                        .filter((item) =>
                            item.permissions
                                ? item.permissions?.some(({ permission, permissionRight }) =>
                                      hasPermission(user, permission, permissionRight ?? PermissionRight.read)
                                  )
                                : true
                        )
                        .map((item) => {
                            if (!item.children) {
                                return (
                                    <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
                                        {item.href ? (
                                            <a href={item.href} target={item.target}>
                                                {item.label}
                                            </a>
                                        ) : (
                                            <Link to={item.to!}>{item.label}</Link>
                                        )}
                                    </Menu.Item>
                                );
                            } else {
                                return (
                                    <Menu.SubMenu
                                        key={item.key}
                                        disabled={item.disabled}
                                        title={item.label}
                                        icon={item.icon}
                                    >
                                        {item.children
                                            .filter((subItem) =>
                                                subItem.permissions
                                                    ? subItem.permissions.some(({ permission, permissionRight }) =>
                                                          hasPermission(
                                                              user,
                                                              permission,
                                                              permissionRight ?? PermissionRight.read
                                                          )
                                                      )
                                                    : true
                                            )
                                            .map((subItem) => (
                                                <Menu.Item
                                                    key={subItem.key}
                                                    icon={subItem.icon}
                                                    disabled={subItem.disabled}
                                                >
                                                    {subItem.href ? (
                                                        <a href={subItem.href} target={subItem.target}>
                                                            {subItem.icon}
                                                            <span>{subItem.label}</span>
                                                        </a>
                                                    ) : (
                                                        <Link to={subItem.to!}>{subItem.label}</Link>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                    </Menu.SubMenu>
                                );
                            }
                        })}
                </Menu>
                <Typography.Text type="secondary" className="text-xs">
                    v{constants.VERSION}
                </Typography.Text>
            </div>
        </Layout.Sider>
    );
};

export default MainMenu;
